/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
	font-family: 'CenturyGothic-Normal';
	src: local('CenturyGothic'),
		url('./fonts/centuryGothic.ttf') format('truetype');
	font-weight: 500;
}
@font-face {
	font-family: 'CenturyGothic';
	src: local('CenturyGothic'),
		url('./fonts/centuryGothicBold.TTF') format('truetype');
	font-weight: bolder;
} */

html,
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
	/* font-family: CenturyGothic;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; */
	background-color: #e0e0e0;
}



.text-normal {
	text-transform: none !important;
}

.ul {
	padding: 0 !important;
	margin: 0 !important;
	font-size: 0.9rem;
	list-style-type: none;
}

.cRed {
	color: red !important;
}

.ReactModal__Overlay {
	z-index: 100000 !important;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
	display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.slick-prev:before,
.slick-next:before {
	color: #2aaaac !important;
}
